import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import BuildingList from "./components/building-list";
import BuildingSearch from "./components/building-search";
import Buildings from "../../common/Buildings";
import Paginator from "../../common/Paginator";
import OpenStreetMap from "../../common/OpenStreetMap";
import { templateVariableValue } from "../../helpers/templateVariables";
import { translateStatic } from "../../helpers/translationHelper";

import * as theme2Styles from "../theme2/scss/theme2.module.scss";

class Theme2BuildingsMap extends Buildings {
  constructor(props) {
    super(props);
  }

  renderBuildings() {
    const { 
      result, 
      location 
    } = this.props;

    const {
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const { latitude, longitude, zoom } = this.state;

    return (
      <div className={`${theme2Styles.mainContent}`}>
        <div className={theme2Styles.wrapper}>
          <div className={`${theme2Styles.mapView}`}>
            <div className={`${theme2Styles.mapViewLeft}`} style={{ height: '650px' }}>
              {latitude && !isNaN(latitude) && longitude && !isNaN(longitude) && 
              <OpenStreetMap 
                listingType="building" 
                listings={this.state.searchedBuildings} 
                latitude={parseFloat(latitude)} 
                longitude={parseFloat(longitude)} 
                zoom={zoom}
              />}
            </div>
            <div className={`${theme2Styles.mapViewRight}`}>
              <div className={`${theme2Styles.propHeader}`}>
                <div className={`${theme2Styles.propHeaderLeft}`}>
                  <h2 className={`${theme2Styles.pageTitle}`}>
                    {translateStatic("buildings", locale, defaultLocale)}&nbsp;
					          <small>({this.state.searchedBuildings.length} {translateStatic("listings", locale, defaultLocale)})</small>
                  </h2>
                </div>
                <div className={`${theme2Styles.propHeaderRight}`}>
                  <select
                    className={`${theme2Styles.field}`}
                    value={this.state.sort}
                    onChange={this.handleSortChange}
                  >
                    <option value="">Sort</option>
                    {Object.keys(this.state.sortOptions).map((key) => {
                      return (
                        <option value={key} key={key}>
                          {this.state.sortOptions[key]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={`${theme2Styles.propScroll}`}>
                <div className={`${theme2Styles.propScrollCont}`}>
                  <BuildingList
                    buildings={this.state.buildings}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <Paginator
                    themeStyles={theme2Styles}
                    paginator={this.state.paginatorInfo}
                    prefix={this.state.navigationPrefix}
                    search={location.search}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {(() => {
          if (this.state.paginatorInfo.total == 0) {
            return (
              <div className={`${theme2Styles.noDataMsg}`}>
                <span>
                  {translateStatic("no_building_found", locale, defaultLocale)}
                </span>
              </div>
            );
          }
        })()}
      </div>
    );
  }

  render() {
    const { 
      result, 
      pageContext, 
      allBuildings, 
      location
    } = this.props;

    const { 
      propertyTypes
    } = pageContext;

    const {
      remarso: {
        domainByURL: {
          website: {
            templateConfiguration,
            template: { templateVariables },
          },
        },
      },
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const metaTitle = templateVariableValue(
      templateConfiguration,
      templateVariables,
      "meta-title-buildings",
      locale,
      defaultLocale
    );

    return (
      <Layout>
        <SEO metaTitle={metaTitle} />

        <div className={`${theme2Styles.listingHeaderC}`}>
          <div className={theme2Styles.wrapper}>
            <div className={`${theme2Styles.listingHeader}`}>
              <BuildingSearch
                buildings={allBuildings}
                setBuildings={this.setBuildings}
                setMapOptions={this.setMapOptions}
                propertyTypes={propertyTypes}
                locale={locale}
                defaultLocale={defaultLocale}
                navigationPrefix={this.state.navigationPrefix}
                location={location}
              />
            </div>
          </div>
        </div>

        {this.renderBuildings()}
      </Layout>
    );
  }
}

export default Theme2BuildingsMap;

Theme2BuildingsMap.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
  allBuildings: PropTypes.array.isRequired,
  location: PropTypes.object,
};
