import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import theme1BuildingsMap from "../themes/theme1/theme1BuildingsMap";
import Theme2BuildingsMap from "../themes/theme2/theme2BuildingsMap";
import Theme3BuildingsMap from "../themes/theme3/theme3BuildingsMap";
import Theme4BuildingsMap from "../themes/theme4/theme4BuildingsMap";
import Theme5BuildingsMap from "../themes/theme5/theme5BuildingsMap";

const BuildingsMap = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    theme1BuildingsMap,
    Theme2BuildingsMap,
    Theme3BuildingsMap,
    Theme4BuildingsMap,
    Theme5BuildingsMap,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(theme1BuildingsMap);
  /** End: Theme Switcher */

  let allBuildings = [];
  const context = require.context("../data/buildings", false, /\.json$/);
  context.keys().forEach(key => {
    const fileName = key.replace('./', '');
    const records = require(`../data/buildings/${fileName}`);
    allBuildings = [...allBuildings, ...records];    
  });

  return <ThemeLoading {...props} allBuildings={allBuildings} />;
};

BuildingsMap.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default BuildingsMap;